@use "../global" as *;

.slick-slider {
  margin-bottom: 50px;
}

.slick-slide {
  padding: 2px 1em;
  @include mq(sp) {
    padding: 2px .5em;
  }
  >div {
    .slide-link {
      position: relative;
      display: block;
      aspect-ratio: 16/9;
      overflow: hidden;
      border-radius: 2em;
      @include mq(sp) {
        border-radius: 1em;
      }
      &::after {
        content: ">";
        font-size: 1.5rem;
        font-weight: 700;
        display: grid;
        place-items: center;
        position: absolute;
        inset: auto 5% 5% auto;
        width: 1.8em;
        height: 1.8em;
        border-radius: 50%;
        background: $white;
        z-index: 100;
        box-shadow: 0px 0px 0px 10px rgba($color: $white, $alpha: .5);
        transition: all .3s;
        transform-origin: center;
        @include mq(sp) {
          font-size: 1rem;
          inset: auto 1em 1em auto;
        }
      }
      &:hover {
        &::after {
          background: #000;
          transform-origin: center;
          box-shadow: none;
          color: $white;
        }
        img {
          transform: scale(1.1);
        }
      }
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: .3s;
    }
  }
}

.slick-dots {
  li {
    button {
      position: relative;
      &::before {
        position: absolute;
        inset: 50% auto auto 50% !important;
        transform: translate(-50%, -50%) !important;
        content: "" !important;
        width: 15px !important;
        height: 15px !important;
        opacity: 1 !important;
        background: $white;
        border-radius: 50%;
        box-shadow: 1px 1px 3px 0px rgba(184, 184, 185, 0.90), -1px -1px 2px 0px rgba(255, 255, 255, 0.90), 1px -1px 2px 0px rgba(184, 184, 185, 0.20), -1px 1px 2px 0px rgba(184, 184, 185, 0.20), -1px -1px 2px 0px rgba(184, 184, 185, 0.50) inset, 1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset;
      }
    }
  }

  .slick-active {
    button {
      &::before {
        background: black;
        box-shadow: none;
      }
    }
  }
}

.slider {
  &__container {
    position: relative;

  }
  &__gao {
    position: relative;
    * {
      background-color: transparent;
    }
    img {
      display: block;
    }
    .gao {
      position: absolute;
      z-index: 2;
      width: 80px;
      inset: auto 0 0 auto;
      animation: sanpo 20s linear 0s infinite;
      &:hover {
        animation-play-state: paused;
      }
      img {
        width: 100%;
        object-fit: contain;
        animation: swing .5s linear 0s alternate infinite, ;
      }
      @include mq(sp) {
        width: 60px;
      }
    }
    @keyframes sanpo {
      0% { transform: translateX(0) scale(-1, 1);}
      49% { transform: translateX(calc(100% - 100vw)) scale(-1, 1);}
      50% { transform: translateX(calc(100% - 100vw)) scale(1, 1);}
      99% { transform: translateX(0) scale(1, 1);}
      100% { transform: translateX(0) scale(-1, 1);}
    }
    @keyframes swing {
      0% { transform: rotate(-2deg);}
      100% { transform: rotateX(2deg);}
    }

    &--bg {
      position: relative;
      width: 100%;
      object-fit: cover;
      img {
        width: 100%;
        min-height: 100px;
        object-fit: cover;
        filter: drop-shadow(0px 0px 1px rgba($color: #000, $alpha: .25));
      }
    }
  }
}