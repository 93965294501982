@use "../global" as *;

.btn {
  &--emergency {
    width: 60px;
    padding-top: 60px;
    background-image: url("/img/emergency.png");
    background-size: 100%;
    background-repeat: no-repeat;
    transition: .5s all;
    &:hover {
      background-image: url("/img/emergency-clicked.png");
    }
    &.clicked {
      width: 60px;
      padding-top: 60px;
      margin-left: -2px;
      background-image: url("/img/emergency-1.png");
      filter: drop-shadow(0 0 2px rgba($color: #000, $alpha: .25));
    }
    &__wrapper {
      display: flex;
      gap: 10px;
      height: 60px;
      width: auto;
      max-width: 60px;
      border-radius: 30px;
      align-items: center;
      transition: max-width .5s ease-in-out;
      @include mq(sp) {
        display: none;
      }
      &.clicked {
        background-color: $red;
        max-width: 300px;
        padding-right: 10px;
      }
      * {
        background-color: rgba($color: #000000, $alpha: 0);
      }
      p {
        display: none;
        word-break: keep-all;
        transition: .5s;
        color: #fff;
        font-weight: bold;
        &.show {
          display: inline-block;
          animation: nobiru .5s linear 0s forwards;
        }
        @keyframes nobiru {
          0% { display: none; }
          90% { display: inline-block; opacity: .2;}
          100% { display: inline-block; opacity: 1;}
        }
      }
    }
  }
  
  &--links {
    width: 60px;
    padding-top: 60px;
    background-image: url("/img/grid-btn.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    transition: .5s all;
    &:hover {
      background-image: url("/img/grid-btn--green.png");
    }
    &.clicked {
      background-image: url("/img/grid-btn--green.png");
    }
  }


  &__login {
    background-color: $dark-green;
    font-size: 1.5em;
    font-weight: 700;
    color: $white;
    border-radius: 30px;
    padding: .3em 3em;
    box-shadow:  1px 1px 3px #1c673b,
                -1px -1px 3px #289153;
    &:hover {
      transform: translateY(3px);
      box-shadow: inset 3px 3px 5px #1c673b,
                  inset -3px -3px 5px #289153;
    }
  }

  &__notice {
    display: flex;
    justify-content: center;
    a {
      display: inline-block;
      position: relative;
      &:hover {
        &::after {
          transform: translate(5px, -50%) rotate(405deg);
          border-color: $white;
        }
        p {
          background-color: $black;
          color: $white;
        }
      }
      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 12px;
        height: 12px;
        inset: 50% 20px auto auto;
        border-top: 3px solid $black;
        border-right: 3px solid $black;
        transform: translateY(-50%) rotate(45deg);
        transition: .5s;
      }
    }
    p {
      transition: .5s;
      position: relative;
      line-height: 1;
      border-radius: 2em;
      display: inline-block;
      padding: 1em 4em;
      box-shadow: 1px 1px 3px 0px rgba(193, 193, 193, 0.90), -1px -1px 2px 0px rgba(255, 255, 255, 0.90), 1px -1px 2px 0px rgba(193, 193, 193, 0.20), -1px 1px 2px 0px rgba(193, 193, 193, 0.20), -1px -1px 2px 0px rgba(193, 193, 193, 0.50) inset, 1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset;
    }
    &.back {
      a {
        &:hover {
          &::after {
            transform: translate(-5px, -50%) rotate(-495deg);
            border-color: $white;
          }
        }
        &::after {
          content: "";
          position: absolute;
          display: inline-block;
          width: 12px;
          height: 12px;
          inset: 50% auto auto 20px;
          border-top: 3px solid $black;
          border-right: 3px solid $black;
          transform: translateY(-50%) rotate(-135deg);
          transition: .5s;
        }
      }
    }
  }
}