@use "../global" as *;

.emergency {
  &__modal {
    display: none;
    &--inner {
      display: none;
    }
    &.open {
      @include mq(sp) {
        display: block;
        width: 100%;
        height: 100vh;
        background-color: rgba($color: $grey, $alpha: .5);
        position: fixed;
        inset: 0 auto auto 0;
        z-index: 102;
      }
      >div {
        @include mq(sp) {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          width: 350px;
          gap: 30px;
          position: fixed;
          inset: 50% auto auto 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          border-radius: 30px;
        }
      }
    }
  }
  &__title {
    color: $red;
    font-size: 1.5rem;
  }
  &__text {
    line-height: 1.2;
    
  }
  &--bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    width: 100%;
    a {
      display: block;
      padding: .4em .8em;
      background-color: $red;
      color: $white;
      line-height: 1;
      border-radius: 5px;
      text-align: center;
    }
    div {
      text-align: center;
      padding: .4em .8em;
      background-color: $grey;
      color: $white;
      line-height: 1;
      border-radius: 5px;
    }
  }
}