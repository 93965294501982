@use "../global" as *;

.nav {
  &--wrapper {
    position: fixed;
    z-index: 103;
    inset: 0 auto auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto 30px;
    padding: 10px 30px;
    border-radius: 0 0 20px 20px;
    box-shadow: 1px 1px 3px 0px rgba(101, 101, 101, 0.90), -1px -1px 2px 0px rgba(255, 255, 255, 0.90), 1px -1px 2px 0px rgba(101, 101, 101, 0.20), -1px 1px 2px 0px rgba(101, 101, 101, 0.20);
    @include mq(sp) {
      padding: 10px 20px;
      border-radius: 0;
    }
  }
  &--right {
    display: flex;
    gap: 10px;
  }

  &__bg {
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    inset: 0 auto auto 0;
    background-color: rgba($color: $grey, $alpha: .5);
    z-index: 100;
    &.clicked {
      display: block;
    }
  }
  &--links {
    max-height: 0px;
    transition: max-height .3s, padding .3s;
    position: fixed;
    &.clicked {
      position: fixed;
      z-index: 102;
      display: flex;
      inset: 0 0 auto auto;
      border-radius: 0 0 30px 30px;
      gap: 10px;
      flex-direction: column;
      box-shadow: 1px 1px 3px 0px rgba(98, 98, 98, 0.90), -1px -1px 2px 0px rgba(255, 255, 255, 0.90), 1px -1px 2px 0px rgba(98, 98, 98, 0.20), -1px 1px 2px 0px rgba(98, 98, 98, 0.20);
      max-height: max-content;
      padding: 100px 20px 20px;
      @include mq(sp) {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
      .item {
        display: flex;
        a {
          display: flex;

        }
        &:first-child {
          display: none;
          @include mq(sp) {
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;
          }
        }
      }

    }
    .item {
      position: relative;
      display: none;
      border-radius: 10px;
      box-shadow: 1px 1px 3px 0px rgba(195, 195, 195, 0.90), -1px -1px 2px 0px rgba(255, 255, 255, 0.90), 1px -1px 2px 0px rgba(195, 195, 195, 0.20), -1px 1px 2px 0px rgba(195, 195, 195, 0.20), -1px -1px 2px 0px rgba(195, 195, 195, 0.50) inset, 1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset;

      a {
        width: 100%;
        justify-content: space-between;
        position: relative;
        padding: 10px;
        gap: 50px;
        @include mq(sp) {
          padding: 10px 15px;
        }
      }
      .left {
        display: flex;
        gap: 5px;
        align-items: center;
        img {
          width: 30px;
          object-fit: contain;
        }
        p {
          font-weight: 700;
        }
      }
      &:hover {
        .arrow {
          background-color: $black;
          &::before {
            border-color: $white;
          }
        }
      }
      .arrow {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        transition: .3s;
        box-shadow: 1px 1px 3px 0px rgba(195, 195, 195, 0.90), -1px -1px 2px 0px rgba(255, 255, 255, 0.90), 1px -1px 2px 0px rgba(195, 195, 195, 0.20), -1px 1px 2px 0px rgba(195, 195, 195, 0.20);
        &::before {
          content: "";
          width: 10px;
          height: 10px;
          left: 0px;
          border-top: 2px solid $black;
          border-right: 2px solid $black;
          transform: translateX(-2px) rotate(45deg);
          transition: .5s;
        }
      }
    }
  }
}