@use "../global" as *;

.form {

  &__container {
    width: 800px;
    min-height: 100lvh;
    height: auto;
    padding: 70px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @include mq(sp) {
      width: calc(100vw - 60px);
    }
  }

  &__title {
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    &::before {
      content: "";
      white-space: pre;
      animation: typing 3.5s forwards 1.2s;
    }
    &::after {
      content: "|";
      animation: blink 4.7s forwards;
    }
    @keyframes typing {
      0% {
        content: '';
      }
      5% {
        content: 'い';
      }
      10% {
        content: 'いー';
      }
      15% {
        content: 'いーが';
      }
      20% {
        content: 'いーがお';
      }
      25% {
        content: 'いーがおぽ';
      }
      30% {
        content: 'いーがおぽー';
      }
      35% {
        content: 'いーがおぽーた';
      }
      40% {
        content: 'いーがおぽーたる';
      }
      45% {
        content: 'イーガオポータル';
      }
      50% {  
        content: 'イーガオポータル';
      }
      55% {
        content: 'イーガオポータルへ';
      }
      60% {
        content: 'イーガオポータルへよ';
      }
      65% {
        content: 'イーガオポータルへよう';
      }
      70% {
        content: 'イーガオポータルへようこ';
      }
      75% {
        content: 'イーガオポータルへようこそ';
      }
      80% {
        content: 'イーガオポータルへようこそ１';
      }
      85% {
        content: 'イーガオポータルへようこそ１';
      }
      90% {
        content: 'イーガオポータルへようこそ';
      }
      95% {
        content: 'イーガオポータルへようこそ！';
      }
      100% {
        content: 'イーガオポータルへようこそ！';
      }
    }
    @keyframes blink {
      0%, 9.99% {
        opacity: 0;
      }
      10%, 19.99% {
        opacity: 1;
      }
      20%, 29.99% {
        opacity: 0;
      }
      30%, 39.99% {
        opacity: 1;
      }
      40%, 49.99% {
        opacity: 0;
      }
      50%, 59.99% {
        opacity: 1;
      }
      60%, 69.99% {
        opacity: 0;
      }
      70%, 79.99% {
        opacity: 1;
      }
      80%, 89.99% {
        opacity: 0;
      }
      90%, 99.99% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @include mq(sp) {
      font-size: 26px;
      word-break: keep-all;
    }
  }

  &__gao {
    width: 250px;
    img {
      width: 100%;
    }
  }

  &__ui {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 450px;
    font-size: 1.1rem;
    @include mq(sp) {
      width: calc(100vw - 60px);
    }
    .email,
    .password {
      display: flex;
      flex-direction: column;
    }
    label {
      padding-bottom: .2em;
      padding-left: 1em;
    }
    .input__wrapper {
      position: relative;
      .eye {
        background-color: rgba($color: #000000, $alpha: 0);
        width: 6%;
        position: absolute;
        inset: 50% 1em auto auto;
        transform: translateY(-50%);
        cursor: pointer;
        img {
          width: 100%;
          background-color: rgba($color: #000000, $alpha: 0);
        }
      }
    }
    input {
      width: 100%;
      line-height: 1.5;
      padding: .5em 1em;
      border-radius: 2em;
      box-shadow: inset 4px 4px 8px #b9b9b9,
                  inset -4px -4px 8px #ffffff;
      &::placeholder {
        color: $grey;
      }
    }
    .error {
      color: red;
      text-align: left;
      margin-top: 5px;
      padding-left: 1.4em;
      font-size: .8rem;
    }
    .forget {
      padding-left: 1em;
      p {
        color: $grey;
        display: inline;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          opacity: .8;
        }
      }
    }
  }

}